.why h2,
.why-detail h2 {
    color: var(--e-global-color-primary);
    font-weight: bold;
}

.why .text-div h2,
.why-detail h2 {
    font-size: 1.9em;
}

.why .card-div h2 {
    font-size: 1.2em;
}

.my-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0px 0px 30px 0px;
    padding: 30px;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.09);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    border-radius: 10px;
}

.why-detail .detail {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--e-global-color-a4537b8);
}


@media (min-width:1024px) {
    .why-detail h2 {
        font-size: 2.4em;
    }
}

@media (min-width:1200px) {
    .why-detail h2 {
        font-size: 3em;
    }
}
.explore {
    background-color: rgb(1, 42, 74);
    color: white;
}

.explore .image-div {
    background-color: transparent;
    background-image: linear-gradient(160deg, rgb(255 255 255) 0%, rgb(72 202 228) 100%);
    opacity: 0.86;
}

.vermel-div {
    padding: 0px 10px 0px 10px;
    background-color: rgb(255 213 0);
    border-radius: 10px 10px 10px 10px;
}

.vermel-div h1,
.here-div h1,
.for-your-care h1 {
    margin: auto;
    padding: 5px 0px;
    font-size: 2.4em;
    font-weight: bold;
}

.vermel-div h1 {
    color: rgb(1, 42, 74);
}
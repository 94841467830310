.careers .career-header h2 {
    font-size: 1.9em;
    color: var(--e-global-color-primary);
    font-weight: bold;
}

@media (min-width:1024px) {
    .careers .career-header h2 {
        font-size: 2.4em;
    }
}

@media (min-width:1200px) {
    .careers .career-header h2 {
        font-size: 3em;
    }
}

.careers h3 {
    padding-top: 10px;
    font-weight: bold;
    color: var(--e-global-color-primary);
    font-size: 1.2em;
}


.careers .career-card {
    background-color: var(--e-global-color-secondary);

    border-radius: 20px;
    overflow: hidden;
    /* max-width: 80%; */
}
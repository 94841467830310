.footer {
    position: relative;
    background-position: center center;
    background-size: cover;
    background-image: url("../assets/assisting-senior-people-female-caregiver-holding-2022-05_003.jpg");
    /* background-color: var(--e-global-color-primary); */
    /* color: var(--e-global-color-a4537b8); */
    padding: 50px 0px 20px 0px;
}

.footer p,
.footer a {
    font-weight: bold;
}

.footer .container-fluid {
    position: relative;
}

.footer hr,
.footer p,
.footer a {
    margin: 30px 0px;
    color: white;
}

.footer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(330deg, var(--e-global-color-primary) 0%, var(--e-global-color-0ad9818) 100%);
    opacity: 0.69;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
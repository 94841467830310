.appointment {
    background-color: var(--e-global-color-secondary);
}

.appointment h2,
.appointment label {
    color: var(--e-global-color-primary);
    font-size: 1.2em;
    font-weight: bold;
}

.appointment label {
    font-size: 1em !important;
}
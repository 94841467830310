.blogs {
    /* background-color: var(--e-global-color-secondary); */
}

.blogs .blog-header h2 {
    font-size: 1.9em;
    color: var(--e-global-color-primary);
    font-weight: bold;
}

@media (min-width:1024px) {
    .blogs .blog-header h2 {
        font-size: 2.4em;
    }
}

@media (min-width:1200px) {
    .blogs .blog-header h2 {
        font-size: 3em;
    }
}

.blogs h5 {
    padding-top: 10px;
    font-weight: bold;
    color: var(--e-global-color-primary);
    font-size: 1.2em;
}
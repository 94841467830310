.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.service-page .baner {
  background-image: url("assets/assisting-senior-people-female-caregiver-holding-2022-05_003.jpg");
}

.career-page .baner {
  background-image: url("assets/photo_2024-01-03_11-56-08-1.jpg");
}

.about-us-page .baner {
  background-image: url("assets/assisting-senior-people-female-caregiver-holding-2022-05_003.jpg");
}

.contact-us-page .baner {
  background-image: url("assets/assisting-senior-people-female-caregiver-holding-2022-05_003.jpg");
}

.baner {
  background-position: center center;
  background-size: cover;
  padding: 9rem 0;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.baner h1 {
  color: whitesmoke;
  font-weight: 700;
  font-size: 2.4em;
}

.baner p {
  color: whitesmoke;
  font-size: 1rem;
  font-weight: 400;
}

@media (min-width:1200px) {
  .baner h1 {
    font-size: 3em;
  }
}

.baner {
  position: relative;
}

.baner-content {
  position: relative;
}

.baner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: linear-gradient(330deg, var(--e-global-color-primary) 0%, var(--e-global-color-0ad9818) 100%);
  opacity: 0.69;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
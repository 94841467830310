.contact-us-form {
    position: relative;
    background-color: var(--e-global-color-secondary);
    border-radius: 10px;
    padding: 30px 10px;
    overflow: hidden;
}

.form-content {
    position: relative;

}

.form-banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-image: linear-gradient(330deg, var(--e-global-color-primary) 0%, var(--e-global-color-0ad9818) 100%);
    opacity: 0.6;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.contact-us .col-12 h2 {
    font-size: 1.9em;
    color: var(--e-global-color-primary);
    font-weight: bold;
}

@media (min-width:1024px) {
    .contact-us .col-12 h2 {
        font-size: 2.4em;
    }
}

@media (min-width:1200px) {
    .contact-us .col-12 h2 {
        font-size: 3em;
    }
}

.contact-us-form h3 {
    color: var(--e-global-color-primary);
    font-weight: bold;
}

.contact-us-form label {
    color: var(--e-global-color-primary);
    font-weight: bold;
}


.form-group {
    margin-bottom: 20px;
}

.contact-us-form p {
    color: var(--e-global-color-primary);
    font-style: italic;
    padding-top: 10px;
    margin-bottom: 0px;
}

.get-in-touch a {
    font-weight: bold !important;
}
.services {
    background-color: var(--e-global-color-secondary);
}

.services .service-header h2 {
    font-size: 1.9em;
    color: var(--e-global-color-primary);
    font-weight: bold;
}

@media (min-width:1024px) {
    .services .service-header h2 {
        font-size: 2.4em;
    }
}

@media (min-width:1200px) {
    .services .service-header h2 {
        font-size: 3em;
    }
}

.service-card {
    position: relative;
}

.service-image {
    border-radius: 20px;
    overflow: hidden;
}

.service-detail {
    position: sticky;
    margin-top: -10%;
    width: 100%;
}

.service-detail .container {
    background-color: var(--e-global-color-a4537b8);
    border-radius: 20px;
    padding: 20px;
}

.service-detail .container h2 {
    color: var(--e-global-color-primary);
    font-weight: bold;
    font-size: 1.5em;
}